
 export const Pricing = () => {
    return (
      <div className='create'>
          <h2>
              Pricing 
          </h2>
          </div>
    )
  }
  