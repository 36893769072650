
 export const Features = () => {
    return (
      <div className='create'>
          <h2>
              Add a new Blog Features
          </h2>
          </div>
    )
  }
  
  
  